import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from './modules/auth/stores/auth-store'
import AdminRoutes from './modules/admin/routes/admin-routes'
import AttachingRoutes from './modules/attaching/routes/attaching-routes'
import AuthRoutes from './modules/auth/routes/auth-routes'
import BillingRoutes from './modules/billing/routes/billing-routes'
import InvestingRoutes from './modules/investing/routes/investing-routes'
import DashboardRoutes from './modules/dashboard/routes/dashboard-routes'
import SandboxRoutes from './modules/sandbox/routes/sandbox-routes'
import SigningRoutes from './modules/signing/routes/signing-routes'
import TaxRoutes from './modules/tax/routes/tax-routes'
import TransactingRoutes from './modules/transacting/routes/transacting-routes'

const routes = [
  ...AdminRoutes,
  ...AttachingRoutes,
  ...AuthRoutes,
  ...BillingRoutes,
  ...InvestingRoutes,
  ...SandboxRoutes,
  ...SigningRoutes,
  ...TaxRoutes,
  ...TransactingRoutes,
  ...DashboardRoutes,

  // catch everything else
  { path: '/:pathMatch(.*)*', redirect: '/' },
]

const router = createRouter({
  history: createWebHistory(),
  // @ts-ignore
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
    return {
      top: 0,
    }
  },
})

// handle auth flow where user arrives from the rails app with a temp token as a GET param
router.beforeEach(async (to, _from, next) => {
  if (to.query.t === undefined) next()

  const authStore = useAuthStore()

  await authStore.refresh(typeof to.query.t === 'string' ? to.query.t : to.query.t.join(''), to)
  const query = { ...to.query }
  delete query.t
  next({ path: to.path, query: query })
})

router.beforeEach(async (to, _from, next) => {
  const authStore = useAuthStore()

  if (['login', 'logout', 'request-password-reset', 'reset-password', 'reset-two-factor'].includes(to.name as string)) {
    next()
  }

  console.log('authStore.is_authenticated', authStore.is_authenticated)

  if (!authStore.is_authenticated) {
    return next({ name: 'login' })
  }

  // if (!authStore.has_current_user) {
  await authStore.refresh(authStore.access_token, to)

  if (!authStore.has_current_user) {
    return next({ name: 'login' })
  }

  if (to.name === 'dashboard' && !authStore.is_site_or_group_admin) {
    return next({
      name: 'investing.individual-overview',
      params: { slug: to.params.slug, individual_id: authStore.current_user.investor_id },
    })
  }
  // }

  next()
})

router.beforeEach((to, _from, next) => {
  // TODO fix typescript error
  // @ts-ignore
  document.title = to.meta?.title || 'Venture360'
  next()
})

router.afterEach((_to, _from) => {
  if (window.Intercom) {
    window.Intercom('update', {})
  }
})

export default router
